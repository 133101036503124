<template>
    <div class="app">
        <div class="topView">
            <img src="./img/download1.png" alt="" style="display: block;width: 100%; padding-bottom: 0px;">
            <div class="downBtn" @click="toDownload"></div>

            <div class="background">
                <SwiperCarousel />
            </div>

            <img src="./img/download2.png" alt="" style="display: block;width: 100%; padding-bottom: 0px;">
            <div style="height: 70px;"></div>
        </div>

        <div class="downloadBottom">
            <div class="downloadView">
                <div class="leftView">
                    <img src="../promotion/img/logo.png" alt="" style="width: 50px;height: 50px;">
                    &nbsp;
                    <div>
                        <div class="leftView">
                            <span style="font-size: 14px;">蝌蚪之家</span>
                            &nbsp;
                            <div class="tag">iOS</div>
                            &nbsp;
                            <div class="tag">安卓</div>
                            &nbsp;
                            <div class="tag">公安已备案</div>
                        </div>
                        <div style="font-size: 12px;color: #666;padding-top: 5px">开发者：胆拖数据服务（上海）有限公司</div>
                    </div>
                </div>
                <div class="downBtnBottom" @click="toDownload">立即下载</div>
            </div>
            <div class="information">
                <span style="color: #666;font-size: 11px;">版本：{{ androidVersion }}</span>
                &nbsp;&nbsp;
                <span style="color: #666;font-size: 11px;" v-if="size != ''">大小：{{ size }}</span>
                &nbsp;&nbsp;
                <a href="https://www.chatgpt5000.com/www/privacys4.html">《隐私政策》</a>
                <a href="https://www.chatgpt5000.com/www/accessAuthority.html">《访问权限》</a>
            </div>
        </div>
    </div>


    <transition name="modal">
        <div class="modal-mask" v-if="showPayTypeModal" @click="showPayTypeModal = false"></div>
    </transition>

    <transition name="slide-bottom">
        <div class="payment-selector" v-if="showPayTypeModal">

            <div class="close">
                <span style="font-size: 16px;font-weight: 600;">下载渠道</span>
                <img src="../share/img/pay_close.png" @click="hide" style="height: 18px;width: 18px;">
            </div>
            <div class="downloadType">
                <a href="https://apps.apple.com/cn/app/id6499296466" data-agl-cvt="6">
                    <div class="downLoadBtn" @click="downloadiOS">
                        <img src="./img/ios.png" alt="" style="width: 25px;">
                        <div>
                            <span>iOS</span>
                            <span>v{{ iosVersion }}</span>
                        </div>
                    </div>
                </a>
                <button class="downLoadBtn" @click="downloadHuaWei"
                    style="border: none; background-color: transparent;">
                    <img src="./img/huawei.png" alt="" style="width: 25px;">
                    <div>
                        <span>华为</span>
                        <span>v{{ androidVersion }}</span>
                    </div>
                </button>
                <button data-agl-cvt="6" class="downLoadBtn" @click="downloadAndroid"
                    style="border: none; background-color: transparent;">
                    <img src="./img/android.png" alt="" style="width: 25px;">
                    <div>
                        <span>安卓</span>
                        <span>v{{ androidVersion }}</span>
                    </div>
                </button>
            </div>
        </div>
    </transition>

    <div class="guide-page" v-if="isWeChat && showGuidePage">
        <img src="../share/img/guide_page.png" style="width: 100%;">
    </div>


</template>
  
<script>

import { appVersionDownload, savePv } from "@/api/api";
import SwiperCarousel from "./SwiperCarousel.vue";
import axios from 'axios'

export default {
    name: "DownloadPage",
    components: {
        SwiperCarousel
    },
    data() {
        return {
            isWeChat: false,  /// 是否微信
            showGuidePage: false,   /// 微信显示引导页
            data: {},
            iosVersion: '',
            androidVersion: '',
            size: '',
            downLoadUrl: '',
            showPayTypeModal: false,   /// 显示下载类型弹窗
            userIP: '',
        }
    },
    computed: {
        
    },
   
    methods: {
        init() {
            appVersionDownload(this.winId).then(res => {
                console.log(res);
                this.iosVersion = res[0].version;
                this.androidVersion = res[1].version;
                if (res[1].size != null) {
                    this.size = res[1].size + 'MB';
                } 
                this.downLoadUrl = res[1].url;
            });
        },
        downloadHuaWei() {
            if (this.isWeChat) {
                this.showGuidePage = true;
                return;
            }
            this.$router.push({ path: '/huaweiDownload', query: { androidVersion: this.androidVersion, downLoadUrl: this.downLoadUrl } });
        },
        downloadAndroid() {
            if (this.isWeChat) {
                this.showGuidePage = true;
                return;
            }
            savePv(this.userIP).then(res => {
                console.log(res);
            });
            // 文件 URL 或数据
            const fileUrl = this.downLoadUrl;
            // 创建隐藏的可下载链接
            const link = document.createElement('a');
            link.href = fileUrl;
            link.style.display = 'none';
            document.body.appendChild(link);
            // 触发点击
            link.click();
            // 清理
            document.body.removeChild(link);
        },
        downloadiOS() {
            if (this.isWeChat) {
                this.showGuidePage = true;
                return;
            }
            savePv(this.userIP).then(res => {
                console.log(res);
            });
        },
        hide() {
            this.showPayTypeModal = false;
        },
        toDownload() {
            if (this.isWeChat) {
                this.showGuidePage = true;
                return;
            }
            this.showPayTypeModal = true;
        },
        async getUserIP() {
            try {
                const response = await axios.get('https://ip.useragentinfo.com/json');
                this.userIP = response.data.ip;
            } catch (error) {
                console.error("Error fetching IP:", error);
            }
        },
        push(){
            this.$router.push({ path: '/accessPermission' });
        }
    },
    created() {
        const ua = navigator.userAgent.toLowerCase();
        this.isWeChat = /micromessenger/.test(ua);
        this.init();
        this.getUserIP();
    }
}

</script>
  
<style scoped lang="scss">
.app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    /* 示例高度 */
    overflow-y: auto;
}

.topView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .downBtn {
        position: absolute;
        top: 600px;
        left: 0;
        right: 0;
        height: 100px;
    }

    .background {
        background-image: url('./img/download3.png');
        background-size: 100% 100%;
        width: 100%;
        height: 220vw;
        padding-top: 5vw;
    }
}

.downloadBottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 12vh;
    background-color: white;
    padding: 10px 0;
    z-index: 100;
    display: flex;
    flex-direction: column;

    .downloadView {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0% 10px;

        .leftView {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;

            .tag {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #db674c;
                height: 16px;
                color: #fff;
                font-size: 10px;
                border-radius: 8px;
                padding: 0% 5px;
            }
        }

        .downBtnBottom {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #2d65d4;
            width: 100px;
            height: 35px;
            color: #fff;
            font-size: 14px;
            border-radius: 17.5px;
        }
    }

    .information {
        padding-left: 65px;
        display: flex;
        flex-direction: row;

        >a {
            color: #3787FF;
            font-size: 10px;
        }
    }
}

.guide-page {
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0.5);
}


.downloadType {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 16px;
}

.downLoadBtn {
    display: flex;
    flex-direction: row;
    /* 设置为列方向 */
    justify-content: center;
    /* 垂直居中对齐 */
    align-items: center;
    height: 44px;
    width: 100%;
    border-radius: 22px;
    background: linear-gradient(to right, #76D6FF, #D1BCFF);
    margin-top: 16px;

    >div {
        display: flex;
        flex-direction: column;
        /* 设置为列方向 */
        justify-content: center;
        /* 垂直居中对齐 */
        align-items: start;
        margin-left: 10px;
        >span:nth-child(1) {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            line-height: 1;
        }
        >span:nth-child(2) {
            margin-top: 3px;
            font-size: 12px;
            color: #fff;
            line-height: 1;
        }
    }
}

.modal-mask {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.payment-selector {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 20px;
}



.slide-bottom-enter-active,
.slide-bottom-leave-active {
    transition: all 0.3s ease;
}

.slide-bottom-enter,
.slide-bottom-leave-to {
    transform: translateY(100%);
}
</style>