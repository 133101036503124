import request from './request'


export function userInfo() {
    return request.post(
        '/user/userInfoByUserId',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function getLuckyDrawInit() {
    return request.post(
        '/app/getLuckyDrawInit',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function getLuckyDraw() {
    return request.post(
        '/app/getLuckyDraw',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function getDrawRecord() {
    return request.post(
        '/app/getDrawRecord',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function getListWinners() {
    return request.post(
        '/app/getListWinnersNew',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function getLuckyDrawTen() {
    return request.post(
        '/app/getLuckyDrawTen',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function inviteTeam(inviteCode) {
    let data = {};
    if (inviteCode !== undefined) {
        data = { 'inviteCode': inviteCode };
    }
    return request.post(
        '/activity/inviteTeam',
        data,
        {
            loading: true,
        }).then(res => res.data);
}
export function initiateTeam(lotteryCode) {
    return request.post(
        '/activity/initiateTeam',
        { 'lotteryCode': lotteryCode },
        {
            loading: true,
        }).then(res => res.data);
}
export function getLotteryResult() {
    return request.post(
        '/activity/getLotteryResult',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function getScrapedFriends() {
    return request.post(
        '/activity/getScrapedFriends',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function pastLog() {
    return request.post(
        '/activity/pastLog',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function getBeInvitationScratchNumber() {
    return request.post(
        '/activity/getBeInvitationScratchNumber',
        {},
        {
            loading: true,
        }).then(res => res.data);
}
export function shareNumber(shareCode) {
    return request.post(
        '/ai/select/getPayshareNumber',
        {
            shareCode: shareCode
        },
        {
            loading: true,
        }).then(res => res.data);
}
// 渠道
export function savePv(userIP) {
    return request.post(
        '/login/savePv',
        {
            'ip': userIP,
            'channel':'baidu'
        },
        {
            loading: true,
        }).then(res => res.data);
}

export function createH5Alipay(productId, userIP, id, os) {
    return request.post(
        '/order/createH5Alipay',
        {
            'productId': productId,
            'channel': 'h5',
            'ip': userIP,
            'id': id,
            'os': os,
        },
        {
            loading: true,
        }).then(res => res.data);
    }

export function createWxH5Orde(productId, userIP, id, os) {
    return request.post(
        '/order/createWxH5Order',
        {
            'productId': productId,
            'channel': 'h5',
            'ip': userIP,
            'id': id,
            'os': os,
        },
        {
            loading: true,
        }).then(res => res.data);
}

export function getAIUserDetailListFilter(lotteryCode, userId, pageNo, issue, prize) {
    return request.post(
        '/ai/select/getAIUserDetailListFilter',
        {
            'lotteryCode': lotteryCode,
            'userId': userId,
            'pageNo': pageNo,
            'issue': issue,
            'prize': prize,
        },
        {
            loading: true,
        }).then(res => res.data);
}

export function getUserWinIssueList(lotteryCode, userId) {
    return request.post(
        '/ai/select/getUserWinIssueList',
        {
            'lotteryCode': lotteryCode,
            'userId': userId,
        },
        {
            loading: true,
        }).then(res => res.data);
}

export function favouriteDetail(winId) {
    return request.post(
        '/ai/select/favouriteDetail',
        {
            'winId': winId,
        },
        {
            loading: true,
        }).then(res => res.data);
}

export function appVersionDownload() {
    return request.post(
        '/app/appVersionDownload',
        {
        },
        {
            loading: true,
        }).then(res => res.data);
}

